<template>
  <li class="col-span-1 bg-white rounded-lg border border-gray-200 shadow divide-y divide-gray-200 overflow-hidden flex flex-col justify-between">
    <div class="w-full divide-y">
      <div class="w-full p-6 space-y-1">
        <div v-for="(entry, index) in installationData" :key="index" class="flex items-start space-x-3" :class="entry.label === 'Instalator: ' && 'pt-4' ">
          <p class="w-1/3 text-gray-500 text-sm">{{ entry.label }}</p>
          <h3 v-html="entry.value" class="w-2/3 text-right text-gray-900 text-sm font-medium"></h3>
        </div>
      </div>
      <div class="w-full p-6">
        <p v-if="deviceDetails" class="text-sm text-gray-500 mb-3">
          {{$t("installation.installationDocumentation")}}
        </p>
        <div class="space-y-1 divide-y divide-gray-200">
          <a v-for="(entry, index) in deviceDownloadables" :key="index" :href="entry.link" target="_blank" class="pt-2 pb-1 text-sm font-medium text-gray-900 w-full flex justify-between">
            {{ entry.label }}
            <ArrowDownTrayIcon class="w-4 h-4" />
          </a>
          <p v-if="installation.status == 'no_warranty'" class="flex text-sm text-yellow-500" :class="installation.status === 'accepted' && 'pt-4'">
            <ExclamationTriangleIcon class="w-4 h-4 flex-shrink-0 mt-0.5 mr-1" />
            {{$t("installation.installationCardMissing")}}
          </p>
        </div>
      </div>
    </div>
    <div v-if="deviceDetails && installation.adminNotes" class="w-full">
      <div class="w-full p-6">
        <p class="text-sm text-gray-500 mb-3">
          {{ $t("notes") }}:
        </p>
        <p class="text-sm font-medium text-gray-900 ">
          {{ installation.adminNotes }}
        </p>
      </div>
    </div>
    <div v-if="!deviceDetails" class="w-full divide-y divide-gray-200">
      <div class="w-full p-6">
        <label for="note" class="block text-sm font-medium text-gray-700">
          {{$t("notes")}}: <span class="text-gray-500">({{$t("adminNotes")}})</span>
        </label>
        <div class="mt-1 relative">
          <textarea
            id="note"
            name="note"
            v-model="notes"
            @change="updateNotes"
            class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
          />
        </div>
      </div>
      <div v-if="installation.status === 'rejected' || installation.status === 'accepted'" class="w-full">
        <div class="w-full p-6">
          <label for="note" class="block text-sm font-medium text-gray-700">
            {{$t("additionalNotes")}}: <span class="text-gray-500">({{$t("adminAdditionalNotes")}})</span>
          </label>
          <div class="mt-1 relative">
            <textarea
              id="rejectionReason"
              name="rejectionReason"
              v-model="rejectionReason"
              @change="updateRejectionReason"
              class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="-mt-px flex divide-x divide-gray-200">
          <div v-if="installation.status === 'pending'" class="w-0 flex-1 flex bg-ifgreen" :title="$t('confirm')">
            <button @click="acceptInstallation" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg hover:bg-ifgreen-dark">
              <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
            </button>
          </div>
          <div v-if="installation.status === 'rejected'" class="w-0 flex-1 flex" :title="$t('installation.restoreToPending')">
            <button @click="revertInstallation" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
              <ArrowLeftIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
            </button>
          </div>
          <div class="w-0 flex-1 flex" :title="$t('installation.instalatorEmail')">
            <a :href="`mailto:${installation.installer.email}`" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
              <EnvelopeIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
            </a>
          </div>
          <div class="-ml-px w-0 flex-1 flex" :title="$t('installation.instalatorPhone')">
            <a :href="`tel:${installation.installer.phone}`" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              <PhoneIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
            </a>
          </div>
          <div class="-ml-px w-0 flex-1 flex" :title="$t('installation.share')">
            <button @click="shareInstallation" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              <div class="relative">
                <UserPlusIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span v-if="installation.receivers && installation.receivers.length" class="absolute -bottom-2 -right-2 w-4 h-4 text-xs bg-yellow-500 text-white rounded-full flex justify-center items-center">{{ installation.receivers.length }}</span>
              </div>
            </button>
          </div>
          <div v-if="installation.status === 'pending'" class="w-0 flex-1 flex bg-red-500" :title="$t('reject')">
            <button @click="rejectInstallation" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg hover:bg-red-600">
              <XMarkIcon class="w-5 h-5 text-white" aria-hidden="true" />
            </button>
          </div>
          <div v-if="['accepted','rejected'].includes(installation.status)" class="w-0 flex-1 flex bg-red-500" :title="$t('delete')">
            <button @click="deleteInstallation" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg hover:bg-red-600">
              <TrashIcon class="w-5 h-5 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { ExclamationTriangleIcon, EnvelopeIcon, PhoneIcon, UserPlusIcon, XMarkIcon, CheckIcon, ArrowDownTrayIcon, ArrowLeftIcon, TrashIcon } from '@heroicons/vue/24/solid'
import { attachTemplate } from '@/assets/js/helpers.js'
import deviceDownloadables from '@/mixins/deviceDownloadables.js'

export default {
  name: "AdminInstallationCard",
  components: {
    EnvelopeIcon,
    PhoneIcon,
    UserPlusIcon,
    ArrowDownTrayIcon,
    XMarkIcon,
    CheckIcon,
    ArrowLeftIcon,
    ExclamationTriangleIcon,
    TrashIcon
  },
  emits: ['update-notes', 'resolve-installation'],
  mixins: [deviceDownloadables],
  props: {
    installation: Object,
    deviceDetails: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notes: this.installation.adminNotes,
      rejectionReason: this.installation.rejectionReason
    }
  },
  computed: {
    installationDevice () {
      return attachTemplate(this.installation.customDevice)
    },
    position () {
      if (this.installation.position) {
        const coords = this.installation.position.split(',')
        if (coords.length > 1) {
          const lat = parseFloat(coords[0]).toFixed(3)
          const lng = parseFloat(coords[1]).toFixed(3)
          return `<a target="_blank" class="underline hover:no-underline" href="http://www.google.com/maps/place/${this.installation.position}">${lat}, ${lng}</a>`
        } else {
          return this.installation.position
        }
      } else {
        return "brak"
      }
    },
    installationData () {
      const  deviceTypeName = this.installationDevice.deviceTemplate.nameTranslations?.[this.$i18n.locale] ? this.installationDevice.deviceTemplate.nameTranslations[this.$i18n.locale] : this.installationDevice.deviceTemplate.name

      const data = [
        {label: `${this.$t("installation.device")}`, value: `<a target="_blank" class="underline hover:no-underline" href="/admin/devices/${this.installationDevice.id}" >${this.installationDevice.deviceTemplate && deviceTypeName}</a>`},
        {label: `${this.$t("installation.installationDate")}`, value: new Date(this.installation.installationDate).toLocaleDateString('pl-PL')},
        {
          label: `${this.$t("installation.installationDate")}`,
          value:
            `${this.installation.customerAddress} <br />
            ${this.installation.customerCity} <br />
            ${this.installation.customerZipcode}`
        },
        {label: `${this.$t("installation.location")}`, value: this.position},
        {label: `${this.$t("installation.customer")}`, value: this.installation.customerName},
        {label: `${this.$t("installation.customerPhone")}`, value: this.installation.customerPhone},
        {label: `${this.$t("installation.installer")}:`, value: this.installation.installer.name},
        {label: `${this.$t("installation.company")}`, value: this.installation.installer.company},
        {label: "Email: ", value: this.installation.installer.email},
        {label: `${this.$t("installation.installerPhoneNumber")}`, value: this.installation.installer.phoneNumber}
      ]
      if (this.deviceDetails) data.splice(0, 1)
      return data
    }
  },
  methods: {
    updateNotes () {
      this.$emit('update-notes', {id: this.installation.id, notes: this.notes})
    },
    updateRejectionReason () {
      this.$emit('update-reason', {id: this.installation.id, reason: this.rejectionReason})
    },
    acceptInstallation () {
      this.$emit('resolve-installation', {
        installation: this.installation,
        action: 'accept',
        title: `${this.$t('installation.acceptInstallationTitle')}`,
        text: `${this.$t('installation.acceptInstallationText1')} ${new Date(this.installation.installationDate).toLocaleDateString('pl-PL')} ${this.$t('installation.acceptInstallationText2')} ${this.installation.installer.name} ${this.$t('installation.acceptInstallationText3')}`
      })
    },
    rejectInstallation () {
      this.$emit('resolve-installation', {
        installation: this.installation,
        action: 'reject',
        title: `${this.$t('installation.rejectInstallationTitle')}`,
        text: `${this.$t('installation.rejectInstallationText1')} ${new Date(this.installation.installationDate).toLocaleDateString('pl-PL')} ${this.$t('installation.rejectInstallationText2')} ${this.installation.installer.name}${this.$t('installation.rejectInstallationText3')}`
      })
    },
    deleteInstallation () {
      this.$emit('resolve-installation', {
        installation: this.installation,
        action: 'delete',
        title: `${this.$t('installation.deleteInstallationTitle')}`,
        text: `${this.$t('installation.deleteInstallationText1')} ${new Date(this.installation.installationDate).toLocaleDateString('pl-PL')} ${this.$t('installation.deleteInstallationText2')} ${this.installation.installer.name} ${this.$t('installation.deleteInstallationText3')}`
      })
    },
    shareInstallation () {
      this.$emit('share-installation', this.installation)
    },
    revertInstallation () {
      this.$emit('resolve-installation', {
        installation: this.installation,
        action: 'revert',
        title: `${this.$t('installation.restoreInstallationTitle')}`,
        text: `${this.$t('installation.restoreInstallationText1')} ${new Date(this.installation.installationDate).toLocaleDateString('pl-PL')} ${this.$t('installation.restoreInstallationText2')} ${this.installation.installer.name} ${this.$t('installation.restoreInstallationText3')}`
      })
    }
  }
}
</script>
